<template>
    <div class="contact content-max-width pt-6">
        <h5 class="d-flex align-center">
            <BaseIcon class="mr-3" symbol-id="icons--mail" />
            Assistance par e-mail
        </h5>
        <p class="primary--text pl-9">
            support@lifestyle.com
        </p>
        <h5 class="d-flex align-center">
            <BaseIcon class="mr-3" symbol-id="icons--phone" />
            Ligne d'assistance urgente
        </h5>
        <p class="primary--text pl-9">
            429-282-2669
        </p>
        <h5 class="d-flex align-center">
            <BaseIcon class="mr-3" symbol-id="icons--facebook" />
            Facebook
        </h5>
        <p class="primary--text pl-9">
            @lifestyle
        </p>
        <h5 class="d-flex align-center">
            <BaseIcon class="mr-3" symbol-id="icons--twitter" />
            Twitter
        </h5>
        <p class="primary--text pl-9">
            @lifestyle
        </p>
        <h5 class="d-flex align-center">
            <BaseIcon class="mr-3" symbol-id="icons--instagram" />
            Instagram
        </h5>
        <p class="primary--text pl-9">
            @lifestyle
        </p>
        <h5 class="d-flex align-center">
            <BaseIcon class="mr-3" symbol-id="icons--location" />
            Emplacement
        </h5>
        <p class="primary--text pl-9">
            292 Lebsack Ports Apt. 498
        </p>
    </div>
</template>
<script>
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';

    export default {
        name: 'Contact',
        components: {
            BaseIcon
        }
    };
</script>
<style lang="scss">
    @import "@scssMixins";
    @import "@scssVariables";

    .contact {
        .gst-svg-icon {
            fill: theme-color( 'tertiary' );
        }
    }
</style>
